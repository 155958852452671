import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import blob from "../../../assets/images/blogbob.svg"
import SnappyLogo from "../../../assets/images/snappylogosmall.png"
import PortfolioCTA from "../../../components/PortfolioCTA"

import { FaPhone } from "react-icons/fa"
import { makeStyles, useTheme } from "@material-ui/core/styles"
// import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
// import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
// import Chip from "@material-ui/core/Chip"
// import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
// import Grow from "@material-ui/core/Grow"
import Hidden from "@material-ui/core/Hidden"
import Paper from "@material-ui/core/Paper"
// import Popper from "@material-ui/core/Popper"
// import MenuItem from "@material-ui/core/MenuItem"
// import MenuList from "@material-ui/core/MenuList"
import Rating from "@material-ui/lab/Rating"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import DirectoryBreadcrumbs from "../../../components/templates/work/DirectoryBreadcrumbs"

const primary = "#26a27b"
const dark = "#3a866e"

const Background = styled.div`
  background-image: url(${blob});
  background-color: #fff;
  background-size: auto;
  background-repeat: no-repeat;
  border-bottom: 2px solid ${primary};
  background-position: 0% 0%;
  color: white;
  padding: 3rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
`

const useStyles = makeStyles((theme) => ({
  sectionCtr: {
    paddingBottom: "2em",
  },
  pageTitle: {
    color: `${dark}`,
    fontWeight: 500,
    fontSize: "1.75rem",
    lineHeight: "2.25rem",
    paddingTop: "2em",
    paddingBottom: ".15em",
  },
  subSectionHeader: {
    fontSize: ".8rem",
    color: "#666666",
    fontWeight: 400,
  },
  subSectionBody: {
    fontSize: ".9rem",
    color: "rgba(0,0,0,.7)",
  },
  servicesHeader: {
    fontSize: "1.5rem",
    color: "#666666",
    fontWeight: 500,
    marginBottom: "1em",
  },
  h1: {
    color: dark,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  h2: {
    color: dark,
    fontWeight: 500,
    fontSize: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  h3: {
    fontSize: "1.05rem",
    lineHeight: "1.25rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  h4: {},
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  h5alt: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
  h6: {
    marginRight: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
    },
  },
  h6alt: {
    marginRight: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
    fontSize: ".9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
    },
  },
  paper: {
    padding: theme.spacing(2.5),
  },
  websiteBtn: {
    fontSize: ".9rem",
    textDecoration: "capitalize",
    backgroundColor: dark,
    color: "#fff",
    borderRadius: 25,
    transition: ".3s",
    width: "60%",
    "&:hover": {
      backgroundColor: primary,
      color: "#fff",
      "& > a": {
        color: "#fff",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      fontSize: ".8rem",
    },
    "& span": {
      textTransform: "capitalize",
    },
  },
  websiteBtnAlt: {
    fontSize: ".9rem",
    textDecoration: "capitalize",
    color: dark,
    borderRadius: 25,
    transition: ".3s",
    width: "60%",
    "&:hover": {
      backgroundColor: primary,
      color: dark,
      "& > a": {
        color: dark,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      fontSize: ".8rem",
    },
    "& span": {
      textTransform: "capitalize",
    },
  },
  aFix: {
    "&:hover > a": {
      color: "#fff",
    },
  },
  companyLogo: {
    maxHeight: 48,
    maxWidth: 48,
  },
  companyLogoAlt: {
    maxHeight: 30,
    maxWidth: 30,
  },
  companyInfoBorder: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
  menuFix: {
    "&:hover > a": {
      color: "inherit",
    },
  },
}))

// const StyledBreadcrumb = withStyles((theme) => ({
//   root: {
//     backgroundColor: "transparent",
//     fontSize: ".8rem",
//     height: theme.spacing(3),
//     fontWeight: theme.typography.fontWeightRegular,
//     "&:hover, &:focus": {
//       backgroundColor: "transparent",
//     },
//     "&:active": {
//       boxShadow: theme.shadows[1],
//       backgroundColor: "transparent",
//     },
//     "& > span:first-child": {
//       color: primary,
//     },
//     [theme.breakpoints.down("xs")]: {
//       fontSize: ".7rem",
//     },
//   },
// }))(Chip)

export default function StateServiceAreaPage({ data }) {
  const theme = useTheme()
  const classes = useStyles()
  // const [open, setOpen] = React.useState(false)
  // const anchorRef = React.useRef(null)
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const {
    allContentfulDirectoryListingCompany: {
      nodes: WebCompanies,
      totalCount: count,
    },
  } = useStaticQuery(cityDirectoryQuery)

  let SnappyCompanyCard = () => (
    <Grid
      container
      justify="center"
      style={{ paddingTop: "1em" }}
      className={classes.sectionCtr}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={8} className={classes.companyInfoBorder}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={1} container alignItems="center">
                        <Avatar
                          variant="rounded"
                          className={classes.companyLogo}
                          src={SnappyLogo}
                          alt="Snappy Web Designs Logo"
                        >
                          S
                        </Avatar>
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              className={classes.h5}
                              style={{
                                marginLeft: matchesXS
                                  ? theme.spacing(2)
                                  : theme.spacing(1),
                              }}
                            >
                              Snappy Web Design
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              className={classes.h6}
                              style={{
                                marginLeft: matchesXS
                                  ? theme.spacing(2)
                                  : theme.spacing(1),
                              }}
                            >
                              We make web design & development easy for small
                              businesses
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: ".75rem" }}>
                    <Rating name="read-only" value={5} readOnly />
                    <span
                      style={{
                        fontSize: "1.15rem",
                        color: `${primary}`,
                        fontWeight: 500,
                        verticalAlign: "top",
                        marginLeft: theme.spacing(0.75),
                      }}
                    >
                      5.0 (Verified)
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                    }}
                  >
                    <Typography variant="body1" paragraph gutterBottom>
                      Snappy Web Design was founded in 2021 to assist small
                      businesses in achieving the same quality of websites as
                      enterprise level corporations. We are experts in bringing
                      small business websites to scale and making them
                      SEO-friendly, responsive, and modern!{" "}
                      <span>
                        <Link to="/">Learn more about Snappy Web Design</Link>
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container justify="center">
                  <Hidden smUp>
                    <Grid item xs={12}>
                      <Divider
                        style={{
                          maxWidth: "95%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "2rem",
                        }}
                      />
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    xs={12}
                    container
                    justify="center"
                    className={classes.aFix}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.websiteBtn}
                      component={Link}
                      to="/"
                    >
                      View Website
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(3),
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          className={classes.subSectionHeader}
                          gutterBottom
                          align="center"
                        >
                          Hourly Rate
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.subSectionBody}
                          gutterBottom
                          paragraph
                          align="center"
                        >
                          $40 - $150/hr
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          className={classes.subSectionHeader}
                          gutterBottom
                          align="center"
                        >
                          Locations
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.subSectionBody}
                          gutterBottom
                          paragraph
                          align="center"
                        >
                          Brighton, MI
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider
            style={{
              maxWidth: "95%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <CardActions>
            <Grid
              item
              xs={12}
              container
              justify={matchesXS ? "center" : undefined}
              direction={matchesXS ? "row" : "row-reverse"}
            >
              <Typography
                variant="subtitle1"
                component={Link}
                to="/contact/"
                style={{
                  color: "rgba(0,0,0,.5)",
                  fontSize: "1rem",
                  paddingRight: theme.spacing(2),
                  textDecoration: "none",
                }}
              >
                <FaPhone style={{ marginRight: theme.spacing(1.25) }} />
                Contact
              </Typography>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen)
  // }

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return
  //   }

  //   setOpen(false)
  // }

  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault()
  //     setOpen(false)
  //   }
  // }

  // // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open)
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus()
  //   }

  //   prevOpen.current = open
  // }, [open])

  return (
    <Layout grey={true}>
      <Seo
        noTitlePrefix={true}
        title={`Best ${count} Web Design Companies Ann Arbor | Snappy Web Design`}
        image="https://snappywebdesign.net/images/directoryimg.jpg"
        description={`Looking for the best web design companies in Ann Arbor MI? Find the top web designer in Ann Arbor to create a website for your company.`}
      />
      <Background>
        <h1 className={classes.h1}>
          Top {count} Web Design Companies <br />
          in Ann Arbor, Michigan
        </h1>
      </Background>
      <DirectoryBreadcrumbs />
      {/* <Breadcrumbs
        aria-label="breadcrumb"
        style={{
          paddingTop: "1em",
          marginLeft: "1em",
          fontSize: matchesXS ? ".7rem" : ".8rem",
        }}
      >
        <Link color="inherit" component={Link} to="/">
          Home
        </Link>
        <StyledBreadcrumb
          label="Top Web Design Companies"
          deleteIcon={<ExpandMoreIcon />}
          onDelete={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
        <Typography
          color="textPrimary"
          style={{ fontSize: matchesXS ? ".7rem" : ".8rem" }}
        >
          Ann Arbor
        </Typography>
      </Breadcrumbs>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuFix}
                >
                  <MenuItem
                    component={Link}
                    style={{ fontSize: ".9rem" }}
                    to="/directory/"
                  >
                    All Companies
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    style={{ fontSize: ".9rem" }}
                    to="/directory/michigan/top-web-design-companies/"
                  >
                    Michigan Companies
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    style={{ fontSize: ".9rem" }}
                    to="/directory/grand-rapids/top-web-design-companies/"
                  >
                    Grand Rapids Companies
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
      <Container
        style={{
          maxWidth: 960,
          backgroundColor: "#f5f5f5",
        }}
      >
        <Grid
          container
          justify="center"
          style={{ paddingTop: ".5em" }}
          className={classes.sectionCtr}
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                variant="h2"
                className={classes.h2}
                paragraph
                gutterBottom
              >
                Best Web Design Agencies in Ann Arbor, MI - Top Web Design Firms
              </Typography>
              <Typography
                variant="h3"
                className={classes.h3}
                paragraph
                gutterBottom
              >
                Looking for the best web designers in Ann Arbor to create your
                businesses website? Rely on our curated list of the best web
                designs companies in Ann Arbor based on their technologies,
                service quality, reviews, and experience. No matter what
                technology you need (Gatsby/React.js/Wordpress and more!), we
                know a web design professional to develop your website!
              </Typography>
              <Divider />
              <Typography
                variant="h4"
                className={classes.h3}
                style={{ paddingTop: "1rem" }}
                paragraph
                gutterBottom
              >
                <span style={{ fontWeight: 600 }}>{count}</span> Companies
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                fontSize: "1.25rem",
                fontWeight: 500,
                paddingTop: "1.5rem",
              }}
              gutterBottom
            >
              Highest Reviewed Web Designers in Ann Arbor | Best Web Design
              Companies
            </Typography>
          </Grid>

          <SnappyCompanyCard />

          {WebCompanies.map((company) => {
            const img = company.img.fixed.src

            if (company.name === "Snappy Web Design") {
              return null
              // return <SnappyCompanyCard key="snappy" />
            }
            return (
              <Grid
                container
                justify="center"
                className={classes.sectionCtr}
                key={company.id}
              >
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          className={classes.companyInfoBorder}
                        >
                          <Grid container justify="center">
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={1} container alignItems="center">
                                  <Avatar
                                    variant="rounded"
                                    className={classes.companyLogoAlt}
                                    src={img}
                                    alt={`${company.name}'s logo`}
                                  >
                                    C
                                  </Avatar>
                                </Grid>
                                <Grid item xs={11}>
                                  <Grid container direction="column">
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="h5"
                                        className={classes.h5alt}
                                        style={{
                                          marginLeft: matchesXS
                                            ? theme.spacing(2)
                                            : theme.spacing(1),
                                        }}
                                      >
                                        {company.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="h6"
                                        className={classes.h6alt}
                                        style={{
                                          marginLeft: matchesXS
                                            ? theme.spacing(2)
                                            : theme.spacing(1),
                                        }}
                                      >
                                        {company.tagline}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: ".75rem" }}>
                              <Rating name="pristine" value={null} readOnly />
                              <span
                                style={{
                                  fontSize: "1.15rem",
                                  color: "rgba(0,0,0,.5)",
                                  fontWeight: 400,
                                  verticalAlign: "top",
                                  marginLeft: theme.spacing(0.75),
                                }}
                              >
                                (?)
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingTop: theme.spacing(1),
                                paddingRight: theme.spacing(1),
                              }}
                            >
                              <Typography
                                variant="body1"
                                paragraph
                                gutterBottom
                              >
                                Description of {company.name} pending...
                                <br />
                                <span>
                                  <a
                                    href={company.url}
                                    target="_blank"
                                    // referrer="noreferrer"
                                    rel="nofollow noreferrer"
                                  >
                                    Learn more about {company.name}
                                  </a>
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Grid container justify="center">
                            <Hidden smUp>
                              <Grid item xs={12}>
                                <Divider
                                  style={{
                                    maxWidth: "95%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: "2rem",
                                  }}
                                />
                              </Grid>
                            </Hidden>
                            <Grid
                              item
                              xs={12}
                              container
                              justify="center"
                              className={classes.aFix}
                            >
                              <Button
                                variant="outlined"
                                size="large"
                                className={classes.websiteBtnAlt}
                                component="a"
                                href={company.url}
                                target="_blank"
                                // referrer="noreferrer"
                                rel="nofollow noreferrer"
                              >
                                View Website
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingTop: theme.spacing(3),
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionHeader}
                                    gutterBottom
                                    align="center"
                                  >
                                    Hourly Rate
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionBody}
                                    gutterBottom
                                    paragraph
                                    align="center"
                                  >
                                    Unknown
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionHeader}
                                    gutterBottom
                                    align="center"
                                  >
                                    Locations
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionBody}
                                    gutterBottom
                                    paragraph
                                    align="center"
                                  >
                                    {company.location}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider
                      style={{
                        maxWidth: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <CardActions>
                      <Grid
                        item
                        xs={12}
                        container
                        justify={matchesXS ? "center" : undefined}
                        direction={matchesXS ? "row" : "row-reverse"}
                      >
                        <Typography
                          variant="subtitle1"
                          component={Link}
                          to="/contact/"
                          style={{
                            color: "rgba(0,0,0,.5)",
                            fontSize: "1rem",
                            paddingRight: theme.spacing(2),
                            textDecoration: "none",
                          }}
                        >
                          <FaPhone
                            style={{ marginRight: theme.spacing(1.25) }}
                          />
                          Update Business Information
                        </Typography>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <PortfolioCTA small={true} />
    </Layout>
  )
}

export const cityDirectoryQuery = graphql`
  {
    allContentfulDirectoryListingCompany(
      filter: { region: { eq: "Ann Arbor" } }
    ) {
      nodes {
        id
        name
        tagline
        rating
        url
        location
        description
        img {
          fixed {
            src
          }
        }
      }
      totalCount
    }
  }
`
